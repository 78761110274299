@charset 'utf-8';

@import 'colors';

// Our variables
$base-font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$base-font-size:   1.125rem;
$base-font-weight: 400;
$small-font-size:  $base-font-size * .875;
$base-line-height: 1.6;

html,
body {
  height: 100%;
  width: 100%;
}

body {
  background-color: $background-color;
  color: $text-color;
  font: $base-font-weight #{$base-font-size} / #{$base-line-height} $base-font-family;
  font-kerning: normal;

  margin: 0;
  word-wrap: break-word;
}

h1,
h2,
h3 {
  line-height: 1.2;
}

article {
  h1 {
    font-size: 2em;
  }
}

a {
  color: darken($highlight-color, 20%);
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;

  max-width: 80vw;
}

@import 'blog';
@import 'nav';
@import 'pagination';
@import 'syntax';
