$primary-color: #c9dbe7;
$highlight-color: #aac6d8;

$light-grey-color: #c3c3c3;
$medium-grey-color: #a7a7a7;
$dark-grey-color: #818181;

$background-color: #fdfdfd;
$code-background-color: #eef;

$text-color: #444;

$nav-background-color: $primary-color;
$nav-title-text-color: $text-color;
$nav-link-text-color: $text-color;
