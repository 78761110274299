.site-header {
  background-color: $nav-background-color;

  .container {
    align-items: center;
    display: flex;
  }
}

.site-title {
  color: $nav-title-text-color;
  padding: 1.25rem 0;
  text-decoration: none;
  text-transform: uppercase;
}

.site-nav {
  margin-left: auto;

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li + li {
      padding-left: 1.25rem;
    }
  }

  a {
    color: $nav-link-text-color;
    display: inline-block;
  }
}
